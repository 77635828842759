import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "constants/constants";

export const useReportsStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxHeight: "calc(100vh - 220px)",
    boxShadow: "none",
    borderRadius: 15,
  },
  button: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  table: {
    width: "100%",
  },
  tableContainer: {
    boxShadow: "none",
    borderRadius: 15,
  },
  listReportRow: {
    "&:hover": {
      background: Colors.GRAY,
    },
  },
  tableCellBig: {
    minWidth: "170px",
    fontSize: "18px",
    textAlign: "center",
  },
  tableCellSmall: {
    minWidth: "150px",
    fontSize: "18px",
    textAlign: "center",
  },
  emptyTable: {
    marginTop: theme.spacing(2),
    alignSelf: "center",
  },
  fontWeight: {
    fontWeight: 700,
    fontSize: "18px",
  },
  tableCell: {
    padding: theme.spacing(2),
    background: Colors.WHITE,
  },
  tableCellTypography: {
    fontSize: 16,
    fontWeight: 600,
  },
  tableBodyCell: {
    padding: theme.spacing(1.5),
  },
  sortCell: {
    display: "flex",
    gap: 8,
    alignItems: "center",
  },
}));

export const useReportStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 252px)",
  },
  button: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  whatsUpButton: {
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#abf7b1" },
    backgroundColor: "#cefad0",
    borderRadius: "5px",
  },
  editButton: {
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#bddfff" },
    backgroundColor: "#e2f1ff",
    borderRadius: "5px",
  },
  deleteButton: {
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#ffcbd1" },
    backgroundColor: "#ffe3e6",
    borderRadius: "5px",
  },
  tableContainer: {
    "&.MuiTableContainer-root": {
      boxShadow: "none",
      borderRadius: 15,
    },
  },
  table: {
    width: "100%",
  },
  tableHead: {
    display: "flex",
    flexDirection: "row",
    position: "sticky",
    top: 0,
    background: "#fff",
    zIndex: 20,
    fontSize: "18px",
  },
  tableRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
  },
  tableCellComment: {
    width: "250px",
    textAlign: "center",
    flexGrow: 1,
    background: "#fff",
    fontSize: "18px",
  },
  tableCellBig: {
    padding: theme.spacing(2),
    width: "190px",
    textAlign: "center",
    flexGrow: 1,
    background: "#fff",
    fontSize: "18px",
  },
  tableCellBigSticky: {
    padding: theme.spacing(2),
    width: "210px",
    textAlign: "center",
    flexGrow: 1,
    position: "sticky",
    left: 0,
    zIndex: 9,
    background: "#fff",
    fontSize: "18px",
  },
  tableCellAlign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableCellSmall: {
    padding: theme.spacing(2),
    width: "110px",
    textAlign: "center",
    flexGrow: 1,
    background: "#fff",
    fontSize: "18px",
  },
  body: {
    width: "100%",
  },
  fontWeight: {
    fontWeight: 700,
    fontSize: "18px",
  },
  selectFieldBig: {
    width: "190px",
    fontSize: "16px",
  },
  tableCellVerySmall: {
    width: "50px",
  },
  input: {
    "& .MuiTextField-input": {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
    },
  },
  greenCell: {
    color: Colors.GREEN,
    background: "#cefad0",
  },
  yellowCell: {
    color: Colors.YELLOW,
    background: "#f7f5bc",
  },
  redCell: {
    color: Colors.RED,
    "MuiAlert-standardError": {
      background: "#ffe3e6",
    }
  },
  greenAlert: {
    background: "#cefad0",
  },
  redAlert: {
    background: "#ffe3e6",
  },
  orangeAlert: {
    background: "#f7f5bc",
  },
  labelwithArrow: {
    "& .MuiSvgIcon-root": {
      opacity: 0.5,
    },
    "&.Mui-disabled": {
      "& .MuiSvgIcon-root": {
        opacity: 0,
      },
    }
  }
}));
